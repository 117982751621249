<template>
  <Layout>
    <b-tabs>
      <b-tab title="English">
        <div class="row">
          <div class="col-12">
            <div class="card">
              <div class="card-body">
                <form-wizard
                  @on-complete="submitData"
                  color="#556ee6"
                  enctype="multipart/form-data"
                  ref="newsletter"
                  back-button-text="Go Back!"
                  next-button-text="Go Next!"
                  :finishButtonText="
                    this.$route.name == 'add-newsletter'
                      ? 'Save Data'
                      : 'Update Data'
                  "
                >
                  <tab-content
                    title="Newsletter Details"
                    icon="mdi mdi-account-details"
                    :before-change="() => validateFormOne()"
                  >
                    <div class="row">
                      <b-form-group id="input-group-1" class="col-6">
                        <label for="input-2"
                          >Newsletter Title
                          <span style="color: red">*</span></label
                        >
                        <b-form-input
                          id="input-2"
                          placeholder="Enter Newsletter Title"
                          v-model="form.title"
                          @keyup.prevent="slugify"
                          :class="{
                            'is-invalid': submitted && $v.form.title.$invalid,
                          }"
                        >
                        </b-form-input>
                        <div
                          v-if="submitted && !$v.form.title.required"
                          class="invalid-feedback"
                        >
                          Newsletter Title is required.
                        </div>
                      </b-form-group>
                      <b-form-group class="col-6">
                        <label for="newsletter_url_link"
                          >URL Link <span style="color: red">*</span></label
                        >
                        <b-form-input
                          id="newsletter_url_link"
                          v-model="form.url_link"
                          placeholder="Enter URL Link"
                        >
                        </b-form-input>
                      </b-form-group>
                    </div>

                    <b-form-group id="input-group-2">
                      <label for="input-2"
                        >Tell Us More About The Newsletter
                        <span style="color: red">*</span></label
                      >
                      <ckeditor
                        v-model="form.description"
                        :class="{
                          'is-invalid':
                            submitted && $v.form.description.$invalid,
                        }"
                      ></ckeditor>
                      <div
                        v-if="submitted && !$v.form.description.required"
                        class="invalid-feedback"
                      >
                        Description is required.
                      </div>
                    </b-form-group>
                    <b-form-group id="input-group-9">
                      <label for="input-9">HTML File Content</label>
                      <b-form-textarea
                        id="input-9"
                        v-model="form.html_content"
                        placeholder="Enter HTML File Content"
                        rows="5"
                      >
                      </b-form-textarea>
                      <!-- <ckeditor v-model="form.html_content"></ckeditor> -->
                    </b-form-group>
                    <b-form-group class="case-img" id="input-group-10">
                      <label for="input-10"
                        >HTML Content Images [Multiple Selection]
                      </label>
                      <b-form-file
                        id="input-10"
                        accept="image/*"
                        multiple="multiple"
                        ref="newsletter_image"
                        placeholder="Choose a file or drop it here... [Upload only 2MB file]"
                        @change="readFile($event, 'image')"
                      ></b-form-file>
                      <div class="case-images">
                        <template
                          v-if="
                            $route.name == 'edit-newsletter' &&
                            html_images.length > 0
                          "
                        >
                          <img
                            v-for="image in html_images"
                            :key="image"
                            :src="form.html_images_path + image"
                            width="128px"
                            height="128px"
                          />
                        </template>
                      </div>
                    </b-form-group>

                    <div class="row">
                      <b-form-group class="col-6" id="input-group-3">
                        <label for="input-3"
                          >Thumbnail Image [Upload Max File Size : 2MB]
                          <span style="color: red">*</span></label
                        >
                        <b-form-file
                          id="input-3"
                          accept="image/*"
                          placeholder="Choose a file or drop it here..."
                          @change="readFile($event, 'image_name')"
                          :class="{
                            'is-invalid': submitted && $v.image_name.$invalid,
                          }"
                          ref="image_name"
                        ></b-form-file>
                        <template
                          v-if="
                            $route.name == 'edit-newsletter' &&
                            edit.image_name_url
                          "
                        >
                          <img
                            :src="edit.image_name_url"
                            width="128px"
                            height="128px"
                            style="object-fit: contain; margin-top: 5px"
                          />
                        </template>
                        <template v-if="image_name_url">
                          <img
                            :src="image_name_url"
                            width="128px"
                            height="128px"
                            ref="image_name_url"
                            style="object-fit: contain; margin-top: 5px"
                          />
                        </template>
                        <div v-if="submitted && !$v.image_name.required" class="invalid-feedback">
                          Thumbnail Image is required.
                        </div>
                      </b-form-group>
                      <b-form-group class="col-6" id="input-group-4">
                        <label for="input-4"
                          >Preview Image [Upload Max File Size : 2MB]
                          <span style="color: red">*</span></label
                        >
                        <b-form-file
                          id="input-4"
                          accept="image/*"
                          placeholder="Choose a file or drop it here..."
                          @change="readFile($event, 'preview')"
                          :class="{
                            'is-invalid': submitted && $v.preview.$invalid,
                          }"
                          ref="preview"
                        ></b-form-file>
                        <template
                          v-if="
                            $route.name == 'edit-newsletter' && edit.preview_url
                          "
                        >
                          <img
                            :src="edit.preview_url"
                            width="128px"
                            height="128px"
                            style="object-fit: contain; margin-top: 5px"
                          />
                        </template>
                        <template v-if="preview_url">
                          <img
                            :src="preview_url"
                            width="128px"
                            height="128px"
                            ref="preview_url"
                            style="object-fit: contain; margin-top: 5px"
                          />
                        </template>
                        <div
                          v-if="submitted && !$v.preview.required"
                          class="invalid-feedback"
                        >
                          Preview Image is required.
                        </div>
                      </b-form-group>
                    </div>

                    <div class="row">
                      <b-form-group id="input-group-5" class="col-6">
                        <label for="input-5">NewsLetter Document [Upload PDF, PPT, PPTX] <span style="color: red;">*</span></label>
                        <b-form-file id="input-5" ref="newsletter_item_image" accept=".pdf,.ppt,.pptx"
                          placeholder="Choose a file or drop it here..." @change="readFile($event, 'file_name')" :class="{
                            'is-invalid': submitted && $v.file_name.$invalid,
                          }"></b-form-file>
                        <template v-if="$route.name == 'edit-newsletter' && showFileName">
                          <a :href="edit.file_name_url" target="_blank">{{
                            file_name
                          }}</a>
                        </template>
                        <div
                          v-if="submitted && !$v.file_name.required"
                          class="invalid-feedback"
                        >
                          Newsletter Document is required.
                        </div>
                      </b-form-group>
                      <b-form-group
                        label="Reference Number"
                        label-for="input-1"
                        id="input-group-1"
                        class="col-6"
                      >
                        <b-form-input
                          id="input-1"
                          placeholder="Enter Reference Number"
                          v-model="form.reference_no"
                        >
                        </b-form-input>
                      </b-form-group>
                    </div>
                  </tab-content>
                  <tab-content
                    title="Add Tags"
                    icon="fa fa-tag"
                    :before-change="() => validateFormTwo()"
                  >
                    <div class="row">
                      <b-form-group class="col-6">
                        <div
                          class="d-flex justify-content-between align-items-center mb-2"
                        >
                          <label for="comm" class="mb-0"
                            >Community <span style="color: red">*</span></label
                          >
                          <b-form-checkbox
                            v-model="status"
                            button
                            button-variant="info"
                            size="sm"
                          >
                            <template v-if="status">Unselect All</template>
                            <template v-else>Select All</template>
                          </b-form-checkbox>
                        </div>
                        <multiselect id="comm" @search-change="fetchCommunity" v-model="form.community_selected"
                          :options="community" :multiple="true" track-by="id" label="title"
                          placeholder="Type here to search" :class="{ 'is-invalid': store && $v.form.community_selected.$invalid }">
                          <span slot="noOptions">
                            Type here to search
                          </span>
                        </multiselect>
                        <div v-if="store && !$v.form.community_selected.required" class="invalid-feedback">
                          Community is required.
                        </div>
                      </b-form-group>
                      <b-form-group
                        class="col-6"
                        label="Country"
                        label-for="input-multi"
                      >
                        <multiselect
                          id="input-multi"
                          @search-change="fetchCountry"
                          v-model="form.country"
                          :options="allCountry"
                          :multiple="true"
                          track-by="name"
                          label="name"
                        >
                        </multiselect>
                      </b-form-group>
                      <b-form-group
                        label="Sub Speciality"
                        label-for="video_sub_spec"
                        class="col-6"
                      >
                        <multiselect
                          id="video_sub_spec"
                          v-model="form.sub_specialities"
                          :options="subspecialities"
                          :multiple="true"
                          track-by="id"
                          label="name"
                          placeholder="Select Sub Speciality"
                        >
                        </multiselect>
                      </b-form-group>
                      <b-form-group
                        class="col-6"
                        label="Knowledge Category"
                        label-for="video_knowledge"
                      >
                        <multiselect
                          id="video_knowledge"
                          v-model="form.knowledge_category"
                          :options="knowledgeCategories"
                          :multiple="true"
                          track-by="id"
                          label="display_name"
                          placeholder="Select Knowledge Category"
                        >
                        </multiselect>
                      </b-form-group>
                      <b-form-group label="Enter Free tags" label-for="tags-separators" class="col-6">
                        <b-form-tags input-id="tags-separators" v-model="form.tags" tag-variant="primary" tag-pills
                          separator=" " placeholder="Enter new tags separated by space and enter">
                        </b-form-tags>
                      </b-form-group>
                      <b-form-group class="col-6" style="display: none" v-model="form.ip_address"></b-form-group>
                      <b-form-group class="col-6" id="input-group-11">
                        <label for="input-2"
                          >Partner <span style="color: red">*</span></label
                        >
                        <b-form-select
                          id="input-2"
                          :options="partners"
                          value-field="id"
                          text-field="title"
                          v-model="form.partner_id"
                          :class="{
                            'is-invalid': store && $v.form.partner_id.$invalid,
                          }"
                        >
                        </b-form-select>
                        <div
                          v-if="store && !$v.form.partner_id.required"
                          class="invalid-feedback"
                        >
                          Partner is required.
                        </div>
                      </b-form-group>
                    </div>
                    <div class="row">
                      <div class="col-12">
                        <div class="thumbnail-card grid-case">
                          <div class="">
                            <b-form-group v-slot="{ ariaDescribedby }">
                              <label class="my-2">Select Doctor posted in forum</label>
                              <b-form-radio-group
                                id="radio-group-1"
                                v-model="form.card_image_show"
                                :options="doctorPostType"
                                :aria-describedby="ariaDescribedby"
                                name="radio-options"
                            ></b-form-radio-group>
                            </b-form-group>
                          </div>
                        </div>
                      </div>
                      <b-form-group label="Member" label-for="member" class="col-6" v-if="form.card_image_show === 1 || form.card_image_show === 2 || form.card_image_show === 4">
                        <multiselect @search-change="fetchMemberList" id="member" v-model="form.member_tagging"
                          :options="memberList" :multiple="false" track-by="member_id" label="fnameAndLname"
                          placeholder="Type here to search"
                          :class="{ 'is-invalid': store && $v.form.member_tagging.$invalid }">
                          <span slot="noOptions">
                            Type here to search
                          </span>
                        </multiselect>
                        <div v-if="store && !$v.form.member_tagging.required" class="invalid-feedback">Member is required when Doctor posted in forum is selected.
                        </div>
                      </b-form-group>
                      <b-form-group class="col-6">
                        <label for="partner_div">Forum <span style="color: red;">*</span></label>
                        <multiselect @search-change="fetchForum" id="partner_div"
                          v-model="form.partner_division_id" :options="forums" :multiple="false" track-by="id"
                          label="title" placeholder="Type here to search" :class="{
                            'is-invalid':
                              store && $v.form.partner_division_id.$invalid,
                          }">
                          <span slot="noOptions">
                            Type here to search
                          </span>
                        </multiselect>
                        <div v-if="store && !$v.form.partner_division_id.required" class="invalid-feedback">
                      Forum is required.
                        </div>
                      </b-form-group>
                      <div class="col-12" v-if="form.card_image_show">
                        <h6>Below is the preview of Doctor posted in forum :</h6>
                        <div class="dc_doctor_main_grid my-4">
                          <div class="dc_doctors_grid" :style="{ gridGap: form.card_image_show === 4 || form.card_image_show === 3 ? '0px' : '5px' }"> 
                            <div class="">
                                <img src="@/assets/images/only_doctor.png" alt="" v-if="form.card_image_show === 4" height="50px" width="50px" />
                                <img src="@/assets/images/only_community.png" alt="" v-if="form.card_image_show === 3" height="50px" width="50px" />
                                <div v-if="form.card_image_show === 1 || form.card_image_show === 2" class="position-relative">
                                    <img src="@/assets/images/only_community.png" alt="" height="40px" width="40px" :style="{ zIndex: form.card_image_show === 1 ? '20' : '10' }" class="dc_comm_abs" />
                                    <img src="@/assets/images/only_doctor.png" alt="" height="40px" width="40px" class="dc_doctor_abs" :style="{ zIndex: form.card_image_show === 1 ? '10' : '20' }" />
                                </div>
                              </div>
                              <div class="dc_main_grid" v-if="form.card_image_show !== 3">
                                <div class="dc_member_name" v-if="form.member_tagging">{{form.member_tagging.fnameAndLname.replace(/[-0-9]/g, "")}}</div>
                                <div class="dc_member_name" v-else>[Member name]</div>
                                <div class="dc_spec_city" v-if="form.partner_division_id">posted in <span class="dc_forum">{{form.partner_division_id.title}}</span></div>
                                <div class="dc_spec_city" v-else>posted in <span class="dc_forum">[forum_name]</span></div>
                              </div>
                              <div v-else style="align-self: center">
                                <div>{{form.partner_division_id.title}}</div>
                              </div>
                          </div>
                          <div class="dc_video_title mt-2 px-4" v-html="form.title"></div>
                          <div class="dc_desc px-4" v-html="form.description"></div>
                          <div>
                              <img src="@/assets/images/thumbnail.jpg" class="dc_thumbnail" />
                          </div>
                          <div class="dc_useful_interaction px-4 pb-3" style="grid-template-columns: 1fr 1fr 1fr">
                              <div style="justify-self: start">
                                <img src="@/assets/images/usefullInteraction/usefull.png" />
                                <span class="ml-2">Useful</span>
                              </div>
                              <div>
                                <img src="@/assets/images/usefullInteraction/save.png" />
                                <span class="ml-2">Save</span>
                              </div>
                              <div style="justify-self: end">
                                <img src="@/assets/images/usefullInteraction/interaction.png" />
                                <span class="ml-2">Share</span>
                              </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                     <b-form-group label="View Multiplication Factor" label-for="view_multi_factor"
                        class="col-4">
                        <b-form-input id="view_multi_factor" type="number"
                            placeholder="Enter View Multiplication Factor"
                            v-model="form.view_multiplication_factor">
                        </b-form-input>
                      </b-form-group>
                      <b-form-group id="input-group-12" class="col-4 position-relative">
                        <label for="input-12">Meta Title</label>
                        <b-form-input
                          id="input-12"
                          placeholder="Enter Meta Title"
                          :maxlength="250"
                          v-model="form.meta_title"
                        >
                        </b-form-input>
                        <div class="text-right">
                          <p
                            v-if="form.meta_title"
                            class="badge position-absolute"
                            style="top: 4px; right: 13px"
                            :class="{
                              'badge-success': form.meta_title.length !== 250,
                              'badge-danger': form.meta_title.length === 250,
                            }"
                          >
                            You typed
                            {{ form.meta_title.length }} out of 250 chars.
                          </p>
                        </div>
                      </b-form-group>
                      <b-form-group class="col-4" id="input-group-14">
                        <label for="input-14">Meta Keywords</label>
                        <b-form-input
                          id="input-14"
                          placeholder="Enter Meta Keywords"
                          v-model="form.meta_keywords"
                        >
                        </b-form-input>
                      </b-form-group>

                      <b-form-group id="input-group-13" class="col-12">
                        <label for="input-13">Meta Description</label>
                        <ckeditor v-model="form.meta_description"></ckeditor>
                      </b-form-group>

                      <b-form-group id="input-group-6" label="Scheduled Date" label-for="input-6" class="col-3" v-if="isSchedule">
                        <b-form-input v-model="form.schedule_date" id="input-6" type="datetime-local"
                          :min="disabledDates()"></b-form-input>
                      </b-form-group>
                    </div>

                    <b-form-group id="input-group-15">
                      <div style="display: flex; gap: 10px">
                        <b-form-checkbox v-model="form.is_active">Is Active</b-form-checkbox>
                        <b-form-checkbox v-model="form.send_to_email" @input="changeSendtoEmail">Is Document sent in
                          mail</b-form-checkbox>
                        <b-form-checkbox v-model="form.is_open_newsletter">Is Open Newsletter</b-form-checkbox>
                        <b-form-checkbox v-model="form.newsletter_show">Newsletter Show</b-form-checkbox>
                        <b-form-checkbox v-model="form.newsletter_schedule" @change="changeSchedule">Newsletter Schedule</b-form-checkbox>
                        <b-form-checkbox v-model="form.is_brand_material">Is Brand Material</b-form-checkbox>
                      </div>
                    </b-form-group>

                    <template v-if="IsSendToEmail">
                      <b-form-group
                        id="input-group-20"
                        label="News Letter Document to - Email [Upload Max File Size : 2MB]"
                        label-for="input-20"
                      >
                        <b-form-file
                          id="input-20"
                          accept="image/*"
                          placeholder="Choose a file or drop it here..."
                          @change="readFile($event, 'email_file')"
                          ref="email_file"
                        ></b-form-file>
                        <template
                          v-if="
                            $route.name == 'edit-newsletter' &&
                            edit.email_file_url
                          "
                        >
                          <img
                            :src="edit.email_file_url"
                            width="128px"
                            height="128px"
                            style="object-fit: contain; margin-top: 5px"
                          />
                        </template>
                        <template v-if="email_file_url">
                          <img
                            :src="email_file_url"
                            width="128px"
                            height="128px"
                            ref="email_file_url"
                            style="object-fit: contain; margin-top: 5px"
                          />
                        </template>
                      </b-form-group>
                    </template>
                  </tab-content>
                </form-wizard>
              </div>
            </div>
          </div>
        </div>
      </b-tab>
      <b-tab title="Indonesia">
        <div class="row">
          <div class="col-12">
            <div class="card">
              <div class="card-body">
                <div class="row">
                  <b-form-group id="input-group-1" class="col-6">
                    <label for="input-2">Newsletter Title</label>
                    <b-form-input
                      id="input-2"
                      placeholder="Enter Newsletter Title"
                      v-model="form.translation.indonesia.title"
                    >
                    </b-form-input>
                  </b-form-group>
                  <b-form-group
                    label="Reference Number"
                    label-for="input-1"
                    id="input-group-1"
                    class="col-6"
                  >
                    <b-form-input
                      id="input-1"
                      placeholder="Enter Reference Number"
                      v-model="form.translation.indonesia.reference_no"
                    ></b-form-input>
                  </b-form-group>
                </div>
                <b-form-group id="input-group-2">
                  <label for="input-2">Tell Us More About The Newsletter</label>
                  <ckeditor
                    v-model="form.translation.indonesia.description"
                  ></ckeditor>
                </b-form-group>
                <b-form-group id="input-group-9">
                  <label for="input-9">HTML File Content</label>
                  <b-form-textarea
                    id="input-9"
                    v-model="form.translation.indonesia.html_content"
                    placeholder="Enter HTML File Content"
                    rows="3"
                  >
                  </b-form-textarea>
                </b-form-group>
                <b-form-group id="input-group-3">
                  <label for="input-indonesia"
                    >Thumbnail Image [Upload Max File Size : 2MB]</label
                  >
                  <b-form-file
                    id="input-indonesia"
                    accept="image/*"
                    placeholder="Choose a file or drop it here..."
                    @change="readFile($event, 'image_name_indonesia')"
                    ref="image_name_indonesia"
                  ></b-form-file>
                  <template
                    v-if="
                      $route.name == 'edit-newsletter' &&
                      edit.image_name_indonesia_url
                    "
                  >
                    <img
                      :src="edit.image_name_indonesia_url"
                      width="128px"
                      height="128px"
                      style="object-fit: contain; margin-top: 5px"
                    />
                  </template>
                  <template v-if="image_name_indonesia_url">
                    <img
                      :src="image_name_indonesia_url"
                      width="128px"
                      height="128px"
                      ref="image_name_indonesia_url"
                      style="object-fit: contain; margin-top: 5px"
                    />
                  </template>
                </b-form-group>
                <div class="row">
                  <b-form-group id="input-group-12" class="col-6">
                    <label for="input-12">Meta Title</label>
                    <b-form-input
                      id="input-12"
                      placeholder="Enter Meta Title"
                      v-model="form.translation.indonesia.meta_title"
                    ></b-form-input>
                  </b-form-group>
                  <b-form-group class="col-6" id="input-group-14">
                    <label for="input-14">Meta Keywords</label>
                    <b-form-input
                      id="input-14"
                      placeholder="Enter Meta Keywords"
                      v-model="form.translation.indonesia.meta_keywords"
                    ></b-form-input>
                  </b-form-group>
                </div>

                <b-form-group id="input-group-13">
                  <label for="input-13">Meta Description</label>
                  <ckeditor
                    v-model="form.translation.indonesia.meta_description"
                  ></ckeditor>
                </b-form-group>
              </div>
            </div>
          </div>
        </div>
      </b-tab>
    </b-tabs>
  </Layout>
</template>

<script>
import Layout from "../../layouts/main";
import MixinRequest from "../../../mixins/request";
import newsletterMixin from "../../../mixins/ModuleJs/newsletter";
import { required, requiredIf } from "vuelidate/lib/validators";
import { FormWizard, TabContent } from "vue-form-wizard";
import Multiselect from "vue-multiselect";

export default {
  mixins: [MixinRequest, newsletterMixin],
  data() {
    return {
      submitted: false,
      store: false,
      title1: "Add Newsletter",
      title2: "Edit Newsletter",
      items: [
        {
          text: "Back",
          href: "/newsletter",
        },
        {
          text: "Data",
        },
      ],
    };
  },
  components: {
    Layout,
    FormWizard,
    TabContent,
    Multiselect,
  },
  validations: {
    form: {
      title: { required },
      partner_id: { required },
      partner_division_id: { required },
      description: { required },
      member_tagging: { required: requiredIf(form => form.card_image_show == 1 || form.card_image_show == 2 || form.card_image_show == 4)},
      card_image_show: { required: requiredIf(form => form.member_tagging != null && form.member_tagging != "")},
      community_selected: { required }
    },
    file_name: { required },
    preview: { required },
    image_name: { required },
  },
  methods: {
    getFormTitle(id) {
      let res = this.forums.filter((f) => f.id == id);
      if (res.length > 0) {
        return res[0].title;
      }
      return null;
    },
    validateFormOne() {
      this.submitted = true;
      if (this.$v.form.title.$invalid || this.$v.form.description.$invalid || this.$v.image_name.$invalid || this.$v.preview.$invalid || this.$v.file_name.$invalid) {
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: "Please Fill The Required Fields",
        });
        return false;
      }
      return true;
    },
    validateFormTwo() {
      this.store = true;
      if (this.$v.form.partner_id.$invalid || this.$v.form.card_image_show.$invalid || this.$v.form.member_tagging.$invalid || this.$v.form.partner_division_id.$invalid || this.$v.form.community_selected.$invalid) {
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: "Please Fill The Required Fields",
        });
        return false;
      }
      return true;
    },
    disabledDates() {
      return new Date().toISOString().slice(0, 16);
    },
  },
};
</script>
<style>
.wizard-icon-circle.checked {
  border: none !important;
}

.vue-form-wizard .wizard-icon-circle .wizard-icon-container {
  border-radius: 50% !important;
}

.case-images {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(130px, 1fr));
}

.case-images .images {
  display: block;
  text-align: center;
}
</style>
